<template>
  <div>
    <!-- 弹窗 -->
    <el-dialog title="售后处理" :visible.sync="dialogVisible" :before-close="onCancel" destroy-on-close>
      <el-form label-width="150px" label-position="right" :model="localDataForm" ref="ruleForm" :rules="rules">
        <el-row>
          <el-col :span="11">
            <el-form-item label="商超名称">
              <el-input v-model="ruleForm.shopName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="订单编号">
              <el-input v-model="ruleForm.orderCode" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>

          <el-col :span="11">
            <el-form-item label="退款金额(元)">
              <el-input v-model="ruleForm.refundAmount" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="配送费(元)">
              <el-input v-model="ruleForm.deliveryAmount" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="责任说明">
              <el-input type="textarea" resize="none" :rows="3" v-model="ruleForm.responsibilityMsg"
                readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item class="logo_pic" label="图片">
              <div class="pic-row">
                <div style="display: flex;" v-if="picUrl?.length > 0">
                  <div class="pic" v-for="item, index in picUrl" :key="index">
                    <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="picUrl">
                    </el-image>
                  </div>
                </div>
                <div class="pic" v-else>
                  <el-image
                    style="width: 100px; height: 100px; display: flex;align-items: center;justify-content: center; background: #F5F7FA;">
                    <div slot='error' class='image-slot' style="color: #C0C4CC;">
                      暂无图片
                    </div>
                  </el-image>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="审核结果">
              <el-select v-model="handleTypeVal" placeholder="请选择" clearable>
                <el-option v-for="item in handleType" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="handleTypeVal == 2">
          <el-col :span="22">
            <el-form-item label="拒绝原因" prop="platformRefuseMsg">
              <el-input type="textarea" :maxlength="50" :rows="3" resize="none" placeholder="请输入拒绝原因"
                v-model="localDataForm.platformRefuseMsg" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" plain @click="onCancel">取 消</el-button>
        <el-button type="primary" size="small" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>


</template>

<script>

export default {
  props: {
    // 接受父级组件的显示标记，用于被watch
    showFlag: {
      type: Boolean
    },
    ruleForm: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      // 处理售后类型
      handleType: [
        {
          value: '1',
          label: '审核通过'
        }, {
          value: '2',
          label: '审核不通过'
        },
      ],
      //  prop 值的局部数据属性  
      localDataForm: this.ruleForm,
      rules: {
        platformRefuseMsg: [
          { required: true, message: '请输入审核不通过原因！', trigger: 'blur' },
        ],
      },
      handleTypeVal: '',//处理售后
      picUrl: [],
    }
  },
  watch: {
    showFlag(newVal) {
      this.dialogVisible = newVal
    },
    'ruleForm'(newValue) {
      // 处理图片数组
      if (newValue.refundPicList.length > 0) {
        const picArray = newValue.refundPicList.reduce((acc, item) => {
          if (item.picUrl) {
            acc.push(item.picUrl);
          }
          return acc;
        }, []);
        this.picUrl = picArray

      } else {
        this.picUrl = [];
      }

    }
  },
  created() {

  },

  methods: {

    // 弹窗输入(优化)
    async submitForm(formName) {
      try {
        // 校验表单 
        await new Promise((resolve, reject) => {
          this.$refs[formName].validate(valid => {
            if (!valid) {
              this.$message.error('不通过原因不能为空！');
              reject(new Error('表单校验失败')); // 拒绝Promise并传递错误  
            } else {
              resolve(); // 校验成功，解决Promise  
            }
          });
        });
        // 准备要发送的数据  
        const data = {
          orderRefundId: this.ruleForm.id,
          platformRefuseMsg: this.localDataForm.platformRefuseMsg || null,//平台拒绝原因
          platformStatus: this.handleTypeVal,//状态：1成功（通过）；2售后拒绝
        };
        // console.log('准备要发送的数据 ', data);
        // 根据状态决定是退款还是拒绝退款
        let response = await this.$axios.post(this.$api.handleOrdersRefund, data);
        // 处理响应结果  
        const { code } = response.data;
        if (code == 100) {
          this.$message({ message: '恭喜你，处理成功!', type: 'success' });
          this.dialogVisible = false;
          this.onCancel(); // 重置+关闭弹窗 
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 检验重置
    resetForm() {
      this.handleTypeVal = '';//处理类型恢复
      this.localDataForm = {};//清空对象
      this.$refs.ruleForm.resetFields();
    },

    // 触发父级组件的closeChildDialog
    onCancel() {
      this.resetForm()//重置校验
      this.$emit('closeChildDialog')

    },
  },
}
</script>

<style scoped lang='scss'>
// 图片
.logo_pic {
  position: relative;

  ::v-deep .el-form-item__content {
    width: 100px;
    height: 100px;
  }

  .pic-row {
    display: flex;

    .pic {
      margin-right: 15px;

      .logo_uploader {
        ::v-deep .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }

        ::v-deep .el-upload:hover {
          border-color: #069bbc;
        }

        .avatar-uploader-icon {
          font-size: 28px;
          color: #069bbc;
          width: 100px;
          height: 100px;
          line-height: 100px;
          text-align: center;
        }

        .logoimg {
          width: 100px;
          height: 100px;
          display: block;
          object-fit: cover;
        }
      }
    }
  }

  .delpic {
    position: absolute;
    top: -12px;
    color: red;
    font-size: 20px;
    cursor: pointer;
  }

  .delpic1 {
    right: -15px;
  }

  .delpic2 {
    right: -134px;
  }

  .delpic3 {
    right: -250px;
  }
}
</style>
